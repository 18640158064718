const axios = require('axios');

/*
const FORBIDDEN = 406
const UNAUTHORIZED = 401
*/
const API_ROOT = "/api/v1"

const isBrowser = typeof window !== "undefined"

let defaultTokens = {
  access_token: null,
  refresh_token: null
}

let tokens = { ...defaultTokens }
let tokenString = isBrowser ? window.localStorage.getItem("jwt"): ""

try {
  if (tokenString) {
    tokens = JSON.parse(tokenString)
  }
} catch(e) {

}

const instance = axios.create({
  headers: {'Authorization': "Bearer " +tokens.access_token}
});

const SetTokens = t => {
  let tokenString = ""
  if (t) {
    tokens = t
    tokenString = JSON.stringify(t)
    window.localStorage.setItem("jwt", tokenString)
  }
}

const requests = {
  del: url =>
    instance
      .del(`${API_ROOT}${url}`),
  get: url =>
    instance
      .get(`${API_ROOT}${url}`),
  getWithBody: (url, body) =>
    instance
      .get(`${API_ROOT}${url}`, body),
  put: (url, body) =>
    instance
      .put(`${API_ROOT}${url}`, body),
  post: (url, body) =>
    instance
      .post(`${API_ROOT}${url}`, body)
}

const Password = {
  resetToken: token => requests.get(`/passwords/reset/${token}`),
  reset: (token, data) => requests.post(`/passwords/reset/${token}/confirm`, data),
  forgot: data => requests.post(`/passwords/forgot`, data),
}

const Account = {
  confirm: token => requests.get(`/confirm/${token}`),
  signup: data => requests.post(`/signup`, data),
  login: data => requests.post(`/login`, data),
  auth: () => requests.get(`/auth`)
}

const agent = {
  Password,
  Account,
  SetTokens
}

export default agent  